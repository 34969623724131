.login-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  background-color:var(--primary-dark);
  overflow: hidden;
  text-align: center;
  font-family: "HelveticaW01-RoundedBd","Helvetica Neue","Helvetica","Arial","Microsoft YaHei New","Microsoft Yahei","SimSun","STXihei",sans-serif;
} 
input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0px 1000px white inset;
}
div.SingUpLoginBox {
  width:fit-content;
  margin:0 auto;
  text-align: center;
  /*justify-items: center;*/
}

div#digitartsDiv {
  background: url(../img/logos/logo_digitarts_boutiqa.png) no-repeat center;
  width: 160px;
  height: 60px;
  background-size: contain;
}
div#logoDiv {
  background: url(../img/logos/logo_libero_yelleow.svg) no-repeat center;
  width: 200px;
  height: 200px;
  background-size: contain;
  margin: 30px auto 0 auto;
}
div#loginDiv {
  background: url(../img/login_libero.png) no-repeat center;
  background-size: contain;
  width:400px;
  height: 170px;
  display: inline-block;
  margin: 40px 0;
}
div#emailDiv {
  position: relative;
  top: 7px;
  left:-2px
}
div#passDiv {
  position: relative;
  top: 38px;
  left: 18px;
}

div#buttonDiv {
  position: relative;
  top: 60px;
    left: 100px;
    width: 200px;
    height: 40px;
}

#subo{
  width: 100%;
  height: 35px;
  background:transparent;
  border: none;
  cursor: pointer;
}

input#email {  margin-left: 50px; }
input#password { margin-left: 8px; }
input#email,input#password {
  background-size: 30px 30px;
  border: solid 0px ;
  font-size: 17px;
  color: black;
  padding-left:5px;
  background-position: 194px 1px;
  width: 250px;
    height: 38px;
  font-weight: bold;
  outline-color: var(--yellow-dark);
}
div#logosDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
    align-items: center;
}
div#logosDiv2 div {
  margin: 0 15px;
}
div#logosDiv2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 50px;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  align-content: center;
  row-gap: 5px;
}


div#authBottom {
  width: 100%;
  text-align: center;
  color:#f2f2f2;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  bottom: 3px;
}
#f1{
  width: 400px
}
.formContainer{
  width:400px;
  margin:0 auto;
}
@media only screen and (min-width: 150px) and (max-width: 600px) , (max-height: 610px) {
div#logoDiv {
    width: 170px;
    height: 170px;
  }

  div#digitartsDiv {
    background: url(../img/logos/logo_digitarts_boutiqa.png) no-repeat center;
    width: 80px;
    height: 35px;
    background-size: contain;
  }
  #f1{
     width: 300px
  }
  .formContainer{
     width:300px;
     margin:0 auto;
  }
  div#loginDiv {
    background: url(../img/login_libero.png) no-repeat center;
      background-size: contain;
      height: 140px;
     display: inline-block;
    width: 300px;
  }
  div#emailDiv {
     position: relative;
    top: 4px;
     left:-8px;
  }
  div#passDiv {
     position: relative;
    top: 26px;
     left: 11px;
  }
                                                                                                                                #subo {
                                                                                                                                  width: 100%;
                                                                                                                                  height: 28px;
                                                                                                                                  background: transparent;
                                                                                                                                  border: none;
                                                                                                                                  cursor: pointer;
                                                                                                                                }
  div#buttonDiv {
     position: relative;
    top: 42px;
      left: 68px;
      width: 165px;
      height: 29px;
  }
  input#email,input#password {
     background-size: 30px 30px;
     border: solid 0px var(--primary-main);
     font-size: 15px;
     color: black;
     padding-left:5px;
    width: 210px;
      height: 35px;
     font-weight: bold;
     background: transparent;
    border: none;
  }

  div#logosDiv {
/*   transform: scale(0.6, 0.6) translate(0, -60px);
      -ms-transform: scale(0.6, 0.6) translate(0, -70px);*/
  /* IE 9 /*/
  }
  div#logosDiv2  {
  /* transform: scale(0.6, 0.6) translate(0, -90px);
                        -ms-transform: scale(0.6, 0.6) translate(0, -90px);*/
    /* IE 9 /*/
  }
}
@media only screen and (max-width: 300px)  {
  .SingUpLoginBox{
    transform: scale(0.9) translate(-2%,0);
  }
}
@media only screen and (max-width: 800px) and (min-height: 1000px)  {
  .SingUpLoginBox{
   transform: scale(1.2) translate(0,20%);
  }
}
@media only screen and (min-width: 1000px) and (min-height: 1000px)  {
  .SingUpLoginBox{
   transform: scale(1.5) translate(0,30%);
  }
}
@media only screen and (max-height: 600px)  {
  .SingUpLoginBox{
   transform: scale(0.8) translate(0,-10%)
  }
}