#about {
  margin: 0 20px;
  width: calc(100% - 40px);
  border-collapse: collapse;
  
}
#about caption{
  background: var(--secondary-light);
  margin-bottom: 5px;
  border: 1px solid var(--secondary-main);
  border-radius: 5px;
  font-weight:  550;
  color: var(--primary-main);
}
#about th {
  border: 1px solid black;
  text-align: center;
  padding: 3px 0px;
  color:white;
  background: black;
}
#about td {
  border: 1px solid #dddddd;
  font-size: 13px;
  background: var(--secondary-light);
  text-align: left;
  padding: 10px 20px;
}
#about tr #datvers {
  border: 1px solid #dddddd;
  background: var(--secondary-main) !important;
  font-weight: 550;
  padding: 0px;
  text-align: center;
}