#selectCat {
    width: 230px !important;
    height: 100%;
}
#chg-com #row-1{display: grid;grid-template-columns: 49% 49%;align-items: center;grid-gap: 10px;margin-bottom: 5px;}
.flex-msl {
    width: 98%;
    display: grid;
    grid-template-columns: 20% 20% 53%;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;margin-left: 10px;margin-top:12px}
#all-div{width:98%;display:block;grid-gap: 25px !important;align-items: flex-start;margin-left: auto;margin-right: auto;
         margin-top:10px;}
/*#all-div #left{background-color: aliceblue;}*/
#all-div #right{margin-top: 20px;}
#title {
    background-color: var(--secondary-light);
    text-align: center;
    color: black;
    font-size: 16px;
    padding: 3px;
    font-weight: 500;
       margin-bottom:10px;}

#t {
    table-layout: fixed;
    width: 85%;
    height: fit-content;
    border-collapse: collapse;
    margin-right: auto;
    margin-left: auto;
}

#t th {
    background-color: var(--primary-main);
    color: white;
}

#t td {
    border: 1px solid #af9393;
}

#t th {
    background: var(--primary-main);
    color: white;
    border: 1px solid #af9393;
}

#t td,
#div-t1 td,
#tab-com td,
#chrg-com td {
    padding: 3px 8px !important;
    font-size: 13px;
}

#t tfoot,
#chrg-com tfoot,
#tab-com tfoot {
    position: sticky;
    bottom: 0;
    background: var(--secondary-main);
    color: black;
}

#t th,
#div-t1 th,
#tab-com th,
#chrg-com th {
    padding: 3px 8px !important;
}
#t #td-1{border:none;border-left:1px solid #af9393;display: grid;grid-template-columns: 15% 35%;}

#div-t1{width:100%;overflow: auto;max-height: 440px !important;height: fit-content;}
#t1{width:100%;height: fit-content;border-collapse: collapse;}
#t1 #first-col{border: none !important;background-color: white !important;}
#t1 #second-header {
    background-color: var(--secondary-main) !important;
    color: black;
    border: 1px solid #ba9c9c;
    font-size: 13px;
}

#t1 #first-header {
    background-color: var(--primary-main) !important;
    color: white;
    border: 1px solid #ba9c9c;
    font-size: 13px;
}

#t1 td:not(:first-child, #second-header) {
    background-color: white;
    border: 1px solid #ba9c9c;
    text-align: center;
    font-size: 13px;
    padding: 2px;
}

#stat-cloture #row-1{display: grid;grid-template-columns:33% 33% 33%;justify-content: center;align-items: center;grid-gap: 5px;}

#div-ag{width: 100%;height: fit-content;text-align: center;}
.com-row{width:92%;display: grid;grid-template-columns:25% 25% 25% 25%;justify-content: center;align-items: center;
         grid-gap: 5px;margin-left: auto;margin-right: auto;margin-top: 5px;margin-bottom:10px !important;}
#total-comm {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: fit-content;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: var(--secondary-light);
    padding: 3px;
}
#divider{width:95%;background-color: #af9393;margin: 10px auto 5px auto;}

#chrg-com {
    width: 100%;
    overflow: auto;
    height: fit-content;
    max-height: 200px;
}

#tab-com {
    border-collapse: collapse;
    width: 99%;
    border: 1px solid #af9393;
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;
}

#head-com {
    background-color: var(--primary-main);
    color: white;
    font-size: 13px;
    height: 18px;
}
#tab-com-cell{text-align: center;white-space:normal;}
#tab-com-cell-right{text-align: right;padding-right: 15px;}
#tab-com-cell-tot {
    border-top: 1px solid #af9393;
    padding: 3px 15px 3px 3px;
    font-size: 13px;
    text-align: right;
}

#paper-clo {
    background-color: var(--secondary-main);
    width: 70%;
    height: fit-content;
    padding: 12px 5px;
    margin-left: auto;
    margin-right: auto;
}
#paper-clo #p{width:60%;margin-left: auto;margin-right: auto;margin-bottom:8px;}
#div-clo{text-align: center;margin-top: 2px;}

#auto-clo .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 6px;
}


.soldesContent{
    grid-template-rows:auto 1fr;
    overflow:hidden
}
.soldesContent2 {
    grid-template-rows: auto 1fr;
    overflow: hidden;
    display: grid;
    height: 100%;
}

.diagPanel,
.diagPanel .MuiBox-root,
.diagPanel .MuiTypography-root {
    height: 100%;
    overflow: hidden;
}

.diagPanel .MuiBox-root {
    padding: 0;
}

/* Table layout */
.tabDiag {
    width: 100%;
    border-collapse: collapse;
    /* Ensures consistent column widths */
}

/* Header cells */
.header-cell {
    max-width: 150px;
    text-align: left;
    border: 1px solid #ccc;
    padding: 8px;
    background-color: #f9f9f9;
    white-space: normal;
    /* Allows multi-line content */
    word-wrap: break-word;
    /* Wrap long words */
    overflow: hidden;
    /* Hides content exceeding the cell */
}

/* Data cells */
.table-cell {
    max-width: fit-content;
    max-height: 60px;
    text-align: left;
    border: 1px solid #ccc;
    padding: 8px;
    white-space: normal;
    /* Allows multi-line content */
    word-wrap: break-word;
    /* Wrap long words */
    overflow: hidden;
    /* Hides content exceeding the cell */
    position: relative;
    /* Needed for inner scrolling */
}

/* Scrolling content */
.table-cell div {
    max-height: 60px;
    overflow-y: auto;
    /* Adds vertical scrolling for overflowing content */
}

#ussdsList{
    min-width:500px;
    display:grid;
    grid-template-rows:auto 1fr;
    overflow:hidden
}
#apisList {
    min-width: 390px;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden
}
#ussdCard{
     margin:10px;
     cursor:pointer;
     border-radius: 10px
}
#cardContent{
    display:grid;
    gap:10px;
    padding:8px
}
.apiCardContent {
    display: grid;
    gap: 10px;
    padding: 8px
}
#cardContent #item1{
    width:fit-content;
    gap:5px
}
#cardContent #item2{
    margin-left:auto;
    width:160px;
    gap:5px
}
#scroll{
    overflow-y:scroll;
    height:auto
}

#formDiag2 #row-1{
    grid-template-columns: 160px 200px 300px auto;
}
#formDiag3 #row-1 {
    grid-template-columns: 160px 200px 300px auto;
}

#dscr{
    display: flex;
    justify-content: end;
}
#buttons{
    grid-template-columns: repeat(2,1fr);

}

.messageTableOutil {
    padding: 35px 10px;
    min-width: 460px;
    text-align: center;
    overflow: auto;
    height: 100%;
}
@media only screen and (min-width: 150px) and (max-width: 1050px) { 
    .com-row{grid-gap:10px; grid-template-columns: repeat(auto-fit, minmax(120px,1fr));justify-content: center;margin-bottom: 10px !important;padding: 5px; } 
    #t #td-1{border:none;border-left:1px solid #af9393;display: grid;grid-template-columns: 40% auto;}
   
}
@media only screen and (min-width: 150px) and (max-width:840px) { 
    #stat-cloture #row-1{display: grid; grid-gap:5px; grid-template-columns: repeat(auto-fit, minmax(140px,1fr)) !important;justify-content: center;} 
    #formDiag2 #row-1{
        grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
    }
}
@media only screen and (min-width: 150px) and (max-width: 770px) { 
    #all-div{display: grid; grid-gap:15px; grid-template-columns: repeat(auto-fit, minmax(95%,1fr));justify-content: center;} 
    #formDiag2 #row-1{
        grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
    }
}
@media only screen and (min-width: 150px) and (max-width: 550px) { 
    .flex-msl{display:grid;grid-gap:10px; grid-template-columns: repeat(auto-fit, minmax(280px,1fr)); width: 95%;}
    #formDiag2 #row-1{
        grid-template-columns: repeat(auto-fit,minmax(140px,1fr));
    }
 
}
