#getHist{  display: grid;grid-template-columns:auto 25% }
#getHist #row-1 { width: 100%; grid-template-columns: 250px 160px 160px; align-items: center ;grid-column-gap: 3px; justify-content: start; }

.tabSolde{
    display: flex;
    justify-content: center;
    width:100%;
    /*height:460px;*/
    min-width:1060;
    overflow: auto;
    padding: 5px 10px 10px 10px;
}
#tableSoldes{
    width:98%;
    border-collapse: collapse;
    margin: auto;
    margin-top: 0;
    border: 1px solid grey;
}
#tableSoldes caption{
    background: var(--secondary-light);
    margin-bottom: 5px;
    border: 1px solid var(--secondary-main);
    border-radius: 5px;
    font-weight:  550;
    color: var(--primary-main);
  }
.soldeLine{
    background: white;
    font-size: 12px;
    font-weight: 400;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.highlightElem{
    background: var(--secondary-light) !important;
    font-weight: 600;
    color: var(--primary-main);
}
.soldeLine td{
    padding: 3px 0;
    display: grid;
    grid-template-columns: 60% auto;
    border-bottom:1px solid #ccc;
    border-right:1px solid grey;
    min-height: 25px;
    align-items: center;
}
.soldeLine td p:first-of-type {
    text-align: start;
    padding-left: 5px;
}
.soldeLine td p:last-child {
    text-align: end;
    padding-right: 5px;
}
.changeColor{
    background: #eee;
}
.clientName{
  text-align: center;
  min-width:200px;
  border-left: 1px solid #ccc;
}
.clientTotal{
  padding-right: 10px !important;
  text-align: right;
  min-width:150px;
  border-right: 1px solid #ccc;
}

.button-flex-wrapper .fieldInf{
    width: 250px;
}

#soldeHead{
    margin-left: 15px;
}

#searchFilter{
  display:grid;
  grid-template-columns: auto 280px;
  margin: 0px 10px;
  align-items: end;
}

#cliInfs{
    display:flex;
    gap: 10px; 
    flex-wrap: wrap;
    border: 1px solid var(--primary-main);
    padding: 5px;
    border-radius: 5px;
    justify-content: center;
    background-color: var(--secondary-light);
}
#cliInfsLegend{
    background: transparent;
    color: var(--primary-dark);
    text-align: center;
}
#cliInfs .fieldInf, #cliInfs .fieldInf2 {
    width: fit-content !important;
    min-width: 130px !important;
}

@media only screen and (min-width: 150px) and (max-width: 750px) {
    #searchFilter{
        grid-gap: 5px; grid-template-columns: auto;
    }  
   
}
@media only screen and (min-width: 150px) and (max-width: 860px) {
    #getHist{
        display: block;
    }  
    #getHist .fieldInf2{
        max-width: 575px;
    }  
    
}
@media only screen and (min-width: 850px) {
    #getHist #row-1{
        width: 100%;
        grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr));;
    }  

    .soldeLine{
        grid-template-columns: repeat(4,1fr);
    }
}
@media only screen and (min-width: 1050px) {
    #getHist #row-1{
        width: 100%;
        grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr));;
    }  

    .soldeLine{
        grid-template-columns: repeat(5,1fr);
    }
}
@media only screen and (min-width: 150px) and (max-width: 650px) {
    #getHist #row-1{
        width: 100%;
        grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr));;
    }  

    .soldeLine{
        grid-template-columns: repeat(2,1fr);
    }
   
}
@media only screen and (min-width: 150px) and (max-width: 450px) {
    .soldeLine{
        grid-template-columns: repeat(1,1fr);
    }
   .changeColor{
    background: #eee;
   }
}