#saisie #row-1 {  grid-template-columns: 16% 16% 16% 16% auto 120px; align-items: center;grid-column-gap: 3px; }
#charge #row-1 {  grid-template-columns: 16% 16% 16% auto 210px 120px; align-items: center;grid-column-gap: 3px; }
#plv #row-1 {grid-template-columns: 25% 25% auto 180px; grid-column-gap: 3px;  }
#filtreStk{
    width:100px;
}
.digPaper{
    margin: 10px 50px;
    padding:5px 10px !important;
    background:var(--secondary-light)  !important;
    border-radius:10px;
    grid-template-columns: repeat(4,1fr) !important;  
}

#saisie .MuiFormControl-marginDense,#charge .MuiFormControl-marginDense,#prv .MuiFormControl-marginDense{
     margin-top: 0px; 
     margin-bottom: 4px;
}
#lotForm{
    display: grid;
    grid-gap:10px;
}
#lotForm .MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 8px 24px;
    overflow-y: hidden !important;
}
.conFrns #idfrns{
    text-align: start;
}
.conLot #idlot{
    text-align: start;
}
:is(.conAgent, .conFrns, .conLot) .smallSelectDT{
    text-align: left; padding-left: 5px;
}

#extrForm{
    display: grid;
    grid-gap: 10px;
  }
#print_row_1 {
    display: grid;
    grid-template-columns: 50% 49.5%;
    align-items: center;
    grid-column-gap: 3px;
    padding-bottom: 5px;
}
#print_roww_1{display: grid;grid-template-columns:50% 49.5%; align-items: center;grid-column-gap: 3px;}
#iQtdis {
    color: var(--primary-dark) !important;
}
#print_row_2{display: grid;grid-template-columns:55% 44.5%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_2{display: grid;grid-template-columns:50% 50%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#print_row_22{display: grid;grid-template-columns:55% 41%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_22{display: grid;grid-template-columns:50% 50%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_1 {
    display: grid;
    grid-template-columns: 40% 40% 19%;
    align-items: center;
    grid-column-gap: 3px;
    margin-bottom: 8px;
}
#autocomp{margin-left: 13px;margin-right: 13px;}
#print-prod{margin-bottom: 15px;}
#print-det{display: grid;grid-template-columns:65% 34%;grid-column-gap: 3px;}
#print-det .MuiCircularProgress-root {
    display:flex;
    position: relative !important; margin: auto !important;
}
#print-det table {
    /*width: 98%;*/
    background-color: rgba(255, 255, 255, 0.5);  
    text-align: center;
    border: 1px solid var(--secondary-main);
}
#paper-p{overflow: auto;}
#print-det thead th{
    box-shadow: 0 5px 10px white;
    background-color: var(--secondary-main);
  }
#print-det th {font-size: 15px;  }
#print-det td {font-size: 13px;  }
#print-det tr:nth-child(odd) {background:#FFF ;}
#print-det tr:nth-child(even) {background:var(--secondary-light)}
#re {
    align-items: center !important;
    width: unset !important;
}
/*p{text-align: center;justify-items: center;font-family: fantasy;}*/

#icon-reset{float: right !important;margin-bottom: 10px;}
#logos-tiket{display: grid;grid-template-columns:30% 30%; align-items: center;grid-column-gap:75px; }

#head-tiket{display: grid;grid-template-columns:50% 49%; align-items: center;grid-column-gap: 3px;font-size: 12px;margin-top: 10px;}
#info-tiket{text-align: end;margin-bottom: 10px;}
#prd-tiket{font-weight: bold;font-size: 16px;}
#info-prd-tiket{display: grid;grid-template-columns:40% 58%;grid-column-gap:11px;font-size: 12px;margin-top: 4px;}
#serial-ticket{font-size: 12px;margin-bottom:10px;margin-top:6px;}
#prod-logo{text-align: center;}
#digit-ticket{font-size: 16px;font-weight: bold;text-align: center;}
#foot1,#foot2,#foot3{font-size: 13px;text-align: center;}
#foot1,#foot2{text-align: center;margin-right: 7px;}
#foot3{text-align: center;}
#tab-reprint{display:grid;grid-template-columns:52% 47%;grid-column-gap:15px;align-items: baseline;}
#card-check{display: grid;align-items: center;grid-template-columns:50% 30% 10%;height:32px !important;font-size: 12px;grid-gap: 10px;}
#card-tick{text-align: end !important;}
#p1{font-weight:bold;width:5cm !important;text-align: center;height: 0.6em;
     border-bottom: 2px dashed black;margin-bottom: 0.5em;font-size:15px;}
#p1:first-line{background-color: white;}
#p2{font-weight:bold;text-align: center;height: 0.6em;
     border-bottom: 2px dashed black;margin-bottom: 0.5em;font-size:15px;}
#p2:first-line{background-color: white;}
#myDialog{visibility: hidden;}
#reprint-select{height:200px;overflow: auto;}
#dup-tick{position:absolute;top:0;float: right;}
#reprint-header{display: grid;grid-template-columns: 85% 15%;grid-gap: 15px;align-items: center;}
/*@media print {
@page {
    size:50mm 70mm !important;
}
}*/
#gprx .MuiDialog-paper {
    overflow-x: hidden !important;
}
#paper-print{height: 245px;overflow: auto;}

#lot-row{display: grid;grid-template-columns: 49% 49%;grid-gap: 10px;width:100%}
#promoForm{
    display: grid;
    gap: 10px;
  }

#nbrPromo {
   display:flex;
   gap:10px;
   align-items: center;
}
#nbrPromo p{
   color: green;
}
/*@media print {
@page {
    size:50mm 82mm !important;
}
}*/
#qot-row{display: grid;grid-template-columns: 49% 49%;align-items: center;grid-gap:5px;align-items: center;}

#filtre-qouta{width: 50%;display: grid;grid-template-columns: 49% 49%;align-items: center;grid-gap: 10px;margin-bottom: 10px;margin-left: auto;
             margin-right: 10px;}

@media only screen and (min-width: 150px) and (max-width: 850px) {
    :is(#saisie,#plv,#charge) #row-1{ grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}  
    #re #reprint_row_1{grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #print-det{ display: block;}
    #paper-print{margin-top: 10px;margin-left:6px;}
}

