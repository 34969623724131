#addCns #row-1 { 
    width:98% !important;
    margin:auto !important;
    height:fit-content;
    grid-gap: 0px;
    grid-template-columns: minmax(100px, 130px) minmax(70px, 90px) minmax(80px, 150px) minmax(80px, 150px) minmax(80px, 150px) minmax(80px, 150px) minmax(70px, 80px) minmax(80px, 100px) minmax(90px, 120px) auto 100px;}
#addCns #row-11 { 
    width:98% !important;
    margin:auto !important;
    height:fit-content;
    grid-gap: 0px;
    grid-template-columns: minmax(100px, 130px) minmax(70px, 90px) minmax(80px, 160px) minmax(80px, 160px) minmax(80px, 240px)  minmax(70px, 80px) minmax(80px, 100px) auto 100px;}
#pcns #row-head{
    width:98%;
    margin:auto;
    grid-template-columns: 300px 150px;
}
#hist #row-1 {  grid-template-columns: 20% 20% auto 150px 120px;}
#consoFiltre {  grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) 120px; align-items:center; grid-gap:5px}
#consoFiltre1 {  grid-template-columns: 85px minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) minmax(100px, 150px) 120px; align-items:center; grid-gap:5px}
.formConso{
    width:100% !important;
}
#addCns{
    width:100% !important; 
    margin-top: 5px; 
}
.consoAct{
  width:98%;
  margin: auto;
}

#encs #encsForm{
    margin: 3px 0;
    display: grid;
    grid-template-columns: 120px 120px auto 100px 100px;
    grid-column-gap: 3px;
    align-items: center;
}
.filterContainer{
    width: 96% !important;
    margin: auto !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
}
.dataContainer{
    height: calc(100vh - 315px);
    width:100%;
    max-height:1500px;
    min-height:calc(100vh - 232x);;
    overflow-y:auto !important;
}
.consoPaper{
    margin: 10px 50px;
    padding:5px 10px !important;
    background:var(--secondary-main)  !important;
    border-radius:10px
}
#consoLabel{
    padding-right: 5px;
}
.consoExpandPaper{
    margin: 3px !important ;
    padding:5px 10px !important;
    background:var(--secondary-light)  !important;
    border-radius:10px
}
.consoExpandPaper:hover{
    background:white!important;
}
.consoExpandPaper #label{
    padding: 0 10px;
    width:150px;
}
.consoExpandPaper #labelSim{
    padding: 0px 10px;
    width:150px;
}
.consoExpandPaperNote{
    margin: 3px !important;
    margin-top:0;
    padding:5px 10px !important;
    background:var(--secondary-light)  !important;
    border-radius:10px
}
.consoExpandPaperNote:hover{
    background:white!important;
}

.consoExpandPaperNote #label{
    padding: 0 10px;
    width:300px;
    height:40px;
}
.textNoteC{
    width:100%;
    height: 100% !important;
    text-align:left !important;
    padding:5px 10px;
    margin-left:10px;
    background:transparent;
    color:black;
    border-radius:3px;
    border-color:var(--secondary-main);
    outline:0;
    overflow-y:auto
 }

 .item1 { grid-area: header; }
 .item2 { grid-area: menu; }
 .item3 { grid-area: main; }
 .item4 { grid-area: right; }

.paperGrid2{
    display:grid !important;
    grid-template:
    'menu main right right right right right right right right';
    grid-gap: 0px;
}

.paperGrid{
    display:grid !important;
    grid-template:
    'header header right right right right right right right right'
    'menu main right right right right right right right right';
    grid-gap: 0px;
}
#prodDet > div > div > div > div.sc-hHEiqL.lfLKwR.rdt_TableBody{
    overflow-x: hidden;
}

#row-prx{display: grid;grid-gap: 2px; grid-template-columns:49% 50% !important;justify-content: center; margin-bottom: 5px !important;}
#mtn-prx .MuiFormHelperText-root {
    color:red;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 2px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

#ordForm {
    display:grid;gap:5px
}
#ordForm #row-1{
    grid-template-columns: repeat(auto-fit,minmax(160px,1fr)); gap:5px
}
#ordForm #row-2{
    grid-template-columns: 80px 120px auto;gap:5px
}
.ordPaper{
    margin: 10px 50px;
    padding:5px 10px !important;
    background:var(--secondary-light)  !important;
    border-radius:10px;
    grid-template-columns: repeat(3, 1fr) !important;
}


#orderField #row-1{
    display: grid; grid-template-columns: repeat(auto-fit, minmax(300px,1fr)); justify-content: center;gap:20px;
}

#orderField #row-1 #row-11{
    display: grid; grid-template-columns: repeat(auto-fit, minmax(140px,1fr)); justify-content: center;
}
#rfdDiag .MuiDialog-paper {
    overflow: hidden !important;
}
#rfdForm {
    padding-top: 24px;

        overflow: hidden;
}

#rfdForm>div {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    /* Fixed width for the second div */
}

#rfdForm .label {
    font-weight: 600;
}

#rfdForm .value {
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Display ellipsis for overflowed text */
}

#rfdForm .towLabels {
    display: flex;
    justify-content: space-between;

}

#rfdForm #infos {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
}
.towLabels>div:nth-child(2) {
    width: 140px;
    /* Fixed width for the second div */
}

.uploadData {
    display: grid;
    justify-items: center;
    /* text-align: center; */
    align-self: center;
    /* margin: 0 auto; */
    height: 100%;
    align-items: center;
}

.uploadData div {
    text-align: center;
}

.uploadData div svg {
    cursor: pointer;
}

.rfdButtons {
    display: grid;
    gap: 5px;
    justify-content: flex-end;
}
.ordersStats {
    display: flex;
    overflow: auto;
    min-height: 70px;
        max-height: 90px;
    gap: 10px;
    width: 100%;
    margin: 5px 20px
}
.messageTable {
    padding: 35px 10px;
    min-width: 460px;
}
@media only screen and (min-width: 150px) and (max-width: 900px) {
    #addCns #row-1,#consoFiltre{ display: grid;grid-gap: 2px; grid-template-columns: repeat(auto-fit, minmax(150px,1fr));justify-content: center; margin-bottom: 5px !important;} 
    #hist #row-1{ display: grid;grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #ordForm #row-2{
        grid-template-columns: repeat(auto-fit,minmax(130px,1fr));gap:5px
    }
  }
@media only screen and (min-width: 150px) and (max-width: 500px) {
    #pcns #row-head,#row-prx{ grid-gap: 2px; grid-template-columns: repeat(auto-fit, minmax(200px,1fr));justify-content: center; margin-bottom: 5px !important;} 
  }
