@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap&text=0123456789");
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=regular');
.container{
    width:70%;
    height:70%;
    position: absolute;
    top:20%;
    left:15%;
    }
.table-header .search{
        width: fit-content;
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
    }
.table-header{
        display: flex;
        width: 98%;
        flex-wrap: wrap;
        margin:auto;
        margin-bottom:3px;
        align-items: center;
}

.head{margin: 0px;}
.info{
    display:'flex';
}
.group{
    margin-right:'10%';
}
.fieldsetTop {
    margin: 5px 30px;
    background: var(--secondary-main);
    border: solid 2px var(--primary-dark);
    border-radius: 8px;
    padding: 5px 20px;
}
.fieldsetTopChg {
    background: var(--secondary-main);
    border: solid 2px var(--primary-dark);
    border-radius: 8px;
    padding: 5px 20px;
    width:100%;
}
/*
.fieldsetTop .MuiAutocomplete-root div {
   height:35px;
}
.fieldsetTop .MuiAutocomplete-root div .MuiOutlinedInput-root{
   background:white;
}
.fieldsetTop .MuiAutocomplete-root div div[class~='Mui-focused']{
   background-color:white !important
}*/

.fieldsetTop2{
    height:fit-content;
    margin: 5px 10px;
    background: var(--secondary-main);
    border: solid 1px var(--primary-dark);
    border-radius: 7px;
    padding: 5px 20px;
    padding-bottom:2px;
}
.fieldsetTopPrint {
    height: fit-content;
    margin: 5px 0px 10px;
    background: var(--secondary-main);
    border: solid 1px var(--primary-dark);
    border-radius: 7px;
    padding: 5px 20px;
    padding-bottom: 2px;
    width: 100%;
}
.fieldsetTop3{
    height:fit-content;
    margin: 5px 10px;
    background: var(--secondary-main);
    border: solid 1px var(--primary-dark);
    border-radius: 7px;
    padding: 0px 5px 20px;
    padding-bottom:2px;
}
legend {
    padding: 5px;
    background: var(--primary-dark);
    color: #fff;
    font-size: 90%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    font-weight: 700;
}
div.rowGrid {
    display: grid;
    margin: 3px 0;
    height:fit-content;
    grid-gap: 3px;
    align-items: center;
}
.grid2{
    display:grid;
    grid-template-columns:repeat(2,1fr);
    grid-gap:5px;
    align-items: center;}

.grid3{
    display:grid;
    grid-template-columns:repeat(3,1fr);
    grid-gap:5px}
span.desc{
    color:gray;
    margin-right: 5px;
}
span.inf{
    font-weight: 700;
    min-width: 65px;
    padding: 0 5px;
    background-color: #d4cfcf;
    border-radius: 3px;
    box-shadow: 1px 1px 1px grey;
    margin-right: 5px;
}

.btn{
    margin:10px;
    margin-bottom:0px;
    float:left;
}
.note-container{
    width:100%;
    height: 16px;
}
.noteContainer{
    width:100%;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.content-editable-multiline{
    min-height: 15px;
    min-width: 100%;
    max-width: 100%;
    color:unset;
    padding: 0px 5px 0px 5px;
    width:auto;
    height: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    box-sizing: border-box;
    display: inline-grid;
    align-content: center;
    white-space: break-spaces;
    margin: 5px 0;
}
.content-editable {
    min-height: 15px;
    min-width: 100%;
    max-width: 100%;
    color:unset;
    padding: 0px 5px 0px 5px;
    width:auto;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    box-sizing: border-box;
    display: inline-grid;
    align-content: center;
}
.content-editable[type=number] {
    text-align: right;
}
:is(.content-editable,.content-editable-multiline)::-webkit-outer-spin-button, 
:is(.content-editable,.content-editable-multiline)::-webkit-inner-spin-button { margin-left: 10px; }
.textarea {
    width: 100% !important;
    display: -webkit-box;
    color:unset;
    padding: 0px 10px 0px 10px;
    width:fit-content;
    height: 1rem;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
 
:is(.content-editable,.content-editable-multiline):hover,.textarea:hover {
    min-width: 100%;
    max-width: 100%;
    background: var(--secondary-main);
}
  
:is(.content-editable,.content-editable-multiline):focus {
    min-width: 100%;
    max-width: 100%;
    background-color: var(--primary-main);
    color: white !important;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    margin: 4px 0;
    border-radius: 5px;
    outline: 0;
}

.textarea:focus {
    display: block;
    position:absolute;
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    overflow: auto;
    resize: both;
    width: 150px;
    height: auto;
    max-height:500px;
    background: var(--primary-main);
    color:white;
    border-color:var(--primary-main);
    border-radius:5px;
    opacity:1;
    outline:0;
    z-index: 90000 !important;
    text-align:left
}
.datatable{
    width: 800px;
    align-self: center;
}
.accord-table{
    padding: 0;
    margin: 0;
    width: 100%;
}

.grid-wrapper {
    align-self: center;
    padding: 0px;
    padding-left: 30px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3px;
    grid-auto-rows: minmax(22px, auto);
    justify-items:center;
}
.grid-wrapper4 {
    align-self: center;
    padding: 0px;
    padding-left: 30px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3px;
    grid-auto-rows: minmax(22px, auto);
    justify-items:center;
}
.grid-wrapper2 {
    align-self: center;
    padding: 0px;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    justify-items:center;
}
.flex-wrapper {
    margin: 0px;
    padding:5px 0px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.flex-wrapper2 {
    margin: 0px;
    padding: 5px 0px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}
.flex-wrapper-centered {
    margin: 0px;
    padding:0px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.flex-wrapper-centeredTop {
    margin: 0px;
    padding:0px;
    width:100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}
.button-flex-wrapper{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.verticalGap{
    gap:5px
}

.root-component{
    width: 100%;
    height: 100px;
    min-height: 500px !important;
    display: block;
    justify-items: center;
    box-sizing: border-box;
}
.compo{
    width: 90%;
    height: 90%;
    margin: 0;
    padding: 0;
}
.scrol{
    overflow: auto;
    height: 400px;
}
.dtIcons{
    width:22px !important;
    max-width: 22px !important;
    min-width: 22px !important;
}
.iconsGrid{grid-template-columns: 50% 50%;display: grid;align-items: center;}

/* Overriding element hover of multiselect*/
.optionContainer li:hover,
.optionContainer .highlight {
  background: #ededed !important;
  color: black !important;
  font-size: 13px;
}

.paperTitle{
    position: relative;
    text-align: center;
    padding: 5px;
    float: left;
    width: 100%;
    height:25px;
    color: white;
    border-radius: 5px;
    background: var(--secondary-dark);
}
.smallSelect .MuiOutlinedInput-multiline{
    padding:0 !important;
    height:23px !important;
}
.smallSelect .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding:0px !important;
    height:23px !important;
    font-size:13px
}
.editableDiv {
    padding-left: 8px;
    min-width: 80px;
}
.smallSelect .MuiOutlinedInput-input{
    padding-top:3px !important;
    padding-bottom:3px !important;
    font-size: 14px;
}
.smallSelectDT{
    width:100%;
    min-width: 120px;
    text-align:center;
    height:17px;
}

.smallSelectDT2{
    width:100%;
    min-width: 120px;
    text-align:start;
    height:16px;
}
.smallSelectDT .MuiInputBase-root,.smallSelectDT2 .MuiInputBase-root,.smallSelectDTT .MuiInputBase-root{
    height:17px;
}
.smallSelectDT .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"],
.smallSelectDT2 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding:0px !important;
    height:17px !important;
    font-size:12px;
    width:auto;
    background:var(--primary-main);
    /*border: 1px solid var(--primary-dark);*/
}

.smallSelectDT .MuiOutlinedInput-input,.smallSelectDT2 .MuiOutlinedInput-input{
    color:white !important;
    padding-top:1px !important;
    padding-bottom:1px !important;
}
.grid-wrapper-field { display: grid; grid-template-columns: auto 1fr;align-items: center;}


.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 6px !important;
    box-shadow: 0px 1px 2px grey;
    background: var(--grey-main) !important;   
}

.smallAutoFiltre .MuiOutlinedInput-notchedOutline{
    border-color: gray;
}
.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0 !important;
    font-size: 12px;
    height: 11px;
    font-weight: 700;
}
.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 4px) scale(1) !important;
    color: black !important;
    font-weight: 600;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.smallAutoFiltre .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

.smallAuto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0 8px !important;
    font-size: 12px;
    height: 13px;
    font-weight: 700;
}
.smallAuto .MuiFormControl-fullWidth{
    margin: 0;
}
.smallText {
    margin: 0 !important;
}

.smallNote .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense,.smallAuto .MuiOutlinedInput-adornedEnd, .smallText .MuiOutlinedInput-adornedEnd{
    background: var(--secondary-light);
}

.MuiFormControl-root {
    width:100% !important;
}

.smallAuto .MuiInputLabel-outlined.MuiInputLabel-marginDense,.smallText .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color:var(--primary-dark);
    font-size: 14px;
    transform: translate(14px, 4px) scale(1) !important;
}

.smallAuto .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
    font-weight: 600;
}
.smallAuto .MuiOutlinedInput-notchedOutline,.smallText .MuiOutlinedInput-notchedOutline, .smallNote .MuiOutlinedInput-notchedOutline {
    border-color: grey;
}

.smallText .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 25px;
    font-size: 12px;
    font-weight: 600;
    background: var(--secondary-light) !important;
    display: grid;
    align-content: center;
    border-radius: 5px;
}
.smallText .MuiFormLabel-Filled{
    font-size: 12px;
    color:gray !important;
}
#searchBar {  
    background: white !important; 
}

.smallElem .MuiFormControl-marginDense {
   margin-bottom:auto  !important;
   margin-top:auto !important;
}
.smallNote .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    width:100%;
}
.smallNote .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 600;
    color: var(--primary-dark);
    font-size: 14px;
}
.InputNbrEditable{
    width:100%;
    text-align: end;
}
.InputNbrEditable2{
    width:100%;
    text-align: center !important;
}

#oneIcons{grid-template-columns:25px}
#twoIcons{grid-template-columns:repeat(2,25px)}
#threeIcons{grid-template-columns:repeat(3,25px)}
#fourIcons{grid-template-columns:repeat(4,25px)}
#fiveIcons{grid-template-columns:repeat(5,25px)}

.borders{
    border:1px solid var(--secondary-main);
    width:100%;
    padding-left: 5px;
    text-align: left;
    border-radius: 3px;
    /*background: var(--grey-main);*/
    box-shadow: 1px 1px 1px var(--secondary-dark);
}
.bordersLbael{
  margin-right: 3px;
}

.bordersEditable {
    min-height: 15px;
    min-width: 30px;
    max-width: 100%;
    color:unset;
    padding: 0px 5px 0px 5px;
    width:auto;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border:none;
    border-radius: 3px;
    background: var(--secondary-main);
   /* box-shadow: 1px 1px 1px var(--secondary-dark);*/
    box-sizing: border-box;
    flex-grow: 1;
    padding-left: 5px;
    text-align: left;
}
.bordersEditable[type=number] {
    text-align: right;
}
.bordersEditable::-webkit-outer-spin-button, 
.bordersEditable::-webkit-inner-spin-button { margin-left: 10px; }

.bordersEditable:hover {
    max-width: 100%;
    color:white;
    background: var(--primary-main);
    box-shadow: 1px 1px 1px var(--primary-dark);
}
  
.bordersEditable:focus {
    max-width: 100%;
    color:white;
    background: var(--primary-main);
    box-shadow: 1px 1px 1px var(--primary-dark);
    outline: 0;
}
.smallIcon{
    width: 20px;
    height:20px;
}
.block-centered{
    display: grid;
    justify-items: center;
    width: fit-content;
    margin-right: 5px;
}
.block-container{
    display: grid;
    width: 100%;
}
.gEDDyP {
    overflow: hidden;
    white-space: nowrap;
     text-overflow:clip !important; 
}
.error-template {
    padding: 40px 15px;
    text-align: center;
}
.container.error-template p h1{
    margin-top:20px;
}
.error-template #err{
width:300px;
height: 300px;
}

.btn img { width: 10px;}
.error-actions {
    display: flex;
    justify-content: center;
}
.error-actions .btn{
   background-color:var(--yellow-dark);
   border:none;
   border-radius: 15px;
   padding: 10px 20px;
   color: var(--primary-dark) !important;
   font-weight: bold;
}
.error-actions .btn:hover{
   background-color:#f6b83d;
   color: white !important;
   font-weight: bold;
   transform: scale(1.1);
   cursor: pointer;
}

.fieldInf,.fieldInf2{
    font-weight: 600;
    min-width: 65px;
    height: fit-content;
    min-height: 18px;
    max-height: 29px;
    padding: 0 5px;
    background-color: var(--secondary-light);
    border-radius: 5px;
    border:1px solid var(--secondary-light);
    color:var(--primary-main);
    outline: none;
    margin-right: 2px;
    margin-bottom: 5px;
    width:100%;
    font-size: 12px;
    text-align: start;
    line-height: 1.1;
    overflow: hidden;
    min-height: 27px;
}
.fieldInf2{
    background-color: var(--secondary-light);
    border:1px solid var(--primary-dark);
    min-height: 30px;
}
.fieldInf .inf,.fieldInf2 .inf{
    display:inline-block;
    box-shadow:none;
    background: inherit;
    height:16px;
    min-height:16px;
    max-height:16px;
    width: fit-content;
}
.fieldInf legend,.fieldInf2 legend{
    padding: 1px 2px 0px 2px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color:var(--primary-dark);
    background: inherit;
}
.fieldInf2 legend{
    padding: 1px 2px 0px 2px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color:var(--primary-dark);
    background: inherit;
}

.invisibleLink{
    text-decoration: none;
    color: inherit;
    height:20px;
    display: grid;
    justify-content: left;
    align-items: center;
}

/*costum tooltip*/
.tooltip {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltip:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltip:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    bottom: 108%;
    color: white;
    content: attr(title);
    left: 20%;
    bottom: -150%;
    padding: 4px 2px;
    position: absolute;
    z-index: 98;
    width: 160px;
    white-space:normal !important

  }
  .displayedDataGrid{
    justify-content: center;
    gap:20px;
    background-color: #fff;
    padding: 0 15px;
    width: fit-content;
    border-radius: 5px;
    margin: auto;
  }
  .staticsHeadWIcon{
    display:grid;
    grid-template-columns: 25px auto;
    align-items: center;
    gap: 10px;
  }
  .staticsHeadWIconDouble{
    display:grid;
    /*transform: scale(0.8);*/
    grid-template-columns: 25px auto auto;
    align-items: center;
    gap: 10px;
  }
  .staticsHead{
    display: grid;
    justify-content: center;
    grid-gap:7px;
    text-align: center;
    width: 100%;
    font-family: "Varela Round", sans-serif;
}
.staticsHead h2{
    font-size: 20px;
    color:grey;
    font-weight: 500;
}
.staticsHead p{
    font-size: 13px;
    color: var(--primary-main);
}

/* Firefox , opera*/
* {
    scrollbar-width: thin;
    scrollbar-color: #cbced0 transparent;
}
/* WebKit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #cbced0;
    border-radius: 20px !important;
        border: 5px solid transparent !important;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #b9bbbb;
}

.noEditibaleNote {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
    max-height: 1.2em;
}

.MuiChip-root {
    font-weight: 600;
    font-size: 14px;
}

#number {
    text-align: end !important;
}

table th {
    font-weight: 550 !important;
    font-size: 13px;
}

.caisse{cursor: pointer;}
#caiss-table{height: 250px;overflow: auto;}
#rad .MuiTypography-body1{
    font-size: 11px !important;
}
.flex-infos{
    display: flex;
    gap:8px;
    align-items: center;
    width: fit-content;
    margin: 2px 3px;
}

.button-flex-wrapper-chg{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
    grid-gap:5px;
}

/*costum tooltip*/
.tooltipU {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltipU:hover:after {
    font-weight:normal;
    font-size:11px ;
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    color: white;
    content: attr(data-tooltip);
    left: 20%;
    padding: 4px 5px;
    bottom: 80%;
    position: absolute;
    z-index: 30000 !important;
    width:220px;
    white-space:normal !important;
    cursor: default;
  }
#diagUser .searchWrapper,#diagRol .searchWrapper{border-radius: 10px;height: fit-content;min-height: 30px !important;border: 1 px solid gray !important;}


#tab table {
    /*width: 98%;*/
    width:100%;
    background-color: rgba(255, 255, 255, 0.5);  
    text-align: center;
    border: 1px solid var(--secondary-main);
}
#tab thead th{
    box-shadow: 0 5px 10px white;
    background-color: var(--secondary-main);
  }
#tab th {font-size: 15px; padding: 5px; }
#tab td {font-size: 13px; padding: 10px 5px;}
#tab tr {background:#FFF ;}

@media only screen and (min-width: 150px) and (max-width: 700px) {
    .search2{
        width: 100%;
        height: min-content;
        display: grid;
        justify-self: right;
        grid-template-columns: 63% 35%;
    }
    .fieldsetTop {
       /* margin: 65px 30px;*/
        background: var(--secondary-main);
        border: solid 2px var(--primary-dark);
        border-radius: 8px;
        padding: 5px 20px;
    }
}