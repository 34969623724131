#vers #row-1 {  grid-template-columns: 25% 25% 25% 25%; margin-bottom:7px}
#vers #row-2 {  grid-template-columns: 30% 25% auto auto; align-items:top }
#vers #row-2 #btn{ width:100%;height: 100%; padding:7px}
#vers #row-1 #btn{ width:100%;height: 100%; padding:7px}

#wal #row-1 {  grid-template-columns: 120px 160px 160px 130px 130px auto; margin-bottom:7px}
#wal #row-2 {  grid-template-columns: 30% 25% auto auto; align-items:top }
#wal #row-2 #btn{ width:100%;height: 100%; padding:7px}
#wal #row-1 #btn{ width:100%;height: 100%; padding:7px}

.without-padding label {
    transform: translate(14px, 7px) scale(1);
  }
  
.without-padding div {
    padding: 0px !important;
}


#bonDT {
  min-height:240px !important;
  max-height:240px !important;
}
#bonDT .rdt_Table{
  max-height:165px !important;
  height: 100%;
  overflow-y: auto;
}
#bonDT .sc-kfYoZR{
  max-height:210px !important;
  min-height:210px !important;
}
#bonDT1 {
  min-height:240px !important;
  max-height:240px !important;
}
#bonDT1 .sc-kfYoZR{
  max-height:179px !important;
  min-height:179px !important;
}

#achatDT1 {
  min-height:175px !important;
  max-height:175px !important;
}
#achatDT {
  min-height:175px !important;
  max-height:175px !important;
}

#achatDT .rdt_Table{
  max-height:134px !important;
  overflow-y: auto;
}
#achatDT .sc-kfYoZR{
  max-height:134px !important;
  min-height:134px !important;
}
#achatDT1 .sc-kfYoZR{
  max-height:134px !important;
  min-height:134px !important;
}
.crnsTotal {
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 5px;
  margin: 5px auto;
  background: var(--secondary-main) !important;
}
#versDT .rdt_Table{
  max-height:270px !important;
  overflow-y: auto;
}
#versDT .sc-kfYoZR{
  max-height:220px !important;
  min-height:220px !important;
}
#versDT1 .sc-kfYoZR{
  max-height:220px !important;
  min-height:220px !important;
}
#achForm,#bonForm,#frnsForm{
  display: grid;
  grid-gap: 10px;
}
#frns .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow-y: hidden !important;
}
#ach.MuiDialogContent-root{
  overflow: hidden;
}


#soldeWallet {
  width:100%;
  border-collapse: collapse;
  margin-top: 5px;
 }

 #soldeWallet{
  width:100%;
  border-collapse: collapse;
  
 }
 #soldeWallet thead{
  background: transparent;
  color: black;
  font-size: 15px !important;
 }
 #soldeWallet tbody{
  border-top: 1px solid black;
 }
 #soldeWallet th{
  font-size: 13px;
  font-weight: 600;
 }
 #soldeWallet tr{
  font-size: 11px;
  font-weight: 600;
  height: 20px;
 }
 #soldeWallet tbody tr:hover{
  background-color: var(--secondary-main);
 }
 #soldeWallet tr td{
  padding:2px 3px;
  text-align: end;
 }



#bonForm #row-1 { grid-template-columns:auto 25% ;grid-gap:5px}
@media only screen and (min-width: 150px) and (max-width: 850px) {
  #bonForm #row-1,#vers #row-1,#vers #row-2,#wal #row-1,#wal #row-2{ grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
}