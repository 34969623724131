#prd {
    width: 100%;
    border-collapse: collapse;
    
  }

.prdPaper {
    margin: 10px 50px;
    padding: 5px 10px !important;
    background: var(--secondary-light) !important;
    border-radius: 10px;
    grid-column-gap: 15px;
}
  #prd th {
    font-size:11px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    min-height:0px;
    height:10px;
    color:white;
    background: var(--secondary-dark);
  }
  #prd td {
    border: 1px solid #dddddd;
    font-size: 13px;
    background: var(--secondary-light);
    text-align: left;
    padding: 10px 20px;
  }
  #prd tr #datvers {
    border: 1px solid #dddddd;
    background: var(--secondary-main) !important;
    font-weight: 550;
    padding: 0px;
    text-align: center;
  }
.descPrd {
    width: 100%;
    height: 100% !important;
    text-align: left !important;
    padding: 5px 10px;
    background: var(--secondary-main);
    color: black;
    border-radius: 3px;
    border-color: var(--secondary-main);
    outline: 0;
    overflow-y: auto;
}
.descPrd:hover,.descPrd:focus {
   
    background: var(--primary-main);
    color: white;
}
#prod .rowGrid { margin:0;}
#prod #row-1 {  grid-template-columns: 40% 30% auto; margin-bottom: 10px;}
#prod #row-2 {  grid-template-columns: 20% 20% 20% 20% auto; margin-bottom: 10px; }
#catg #row-1 {  grid-template-columns: repeat(3,1fr)}
#catg #row-2 {  grid-template-columns: 20% 20% 20% auto }
#prod #row-2 #btn{ width:100%;height: 100%; padding:7px}

#storeDiv{ width:170px;height:130px;background:url(../img/box.png) no-repeat center;background-size:165px; }
#storeDiv #inside{ height:100%;padding:0; font-size:11px;}
#storeDiv #inside ul{ text-decoration:none;list-style-type: none !important;}
#storeDiv #inside #achat{  position: relative; top:20px; left:15px;list-style-type: none !important; width:55px;}
#storeDiv #inside #vente{  position: relative; top:43px; left:98px;list-style-type: none !important; width:55px; text-align:center !important;}
#storeDiv #inside #stock{  position: relative; top:45px; left:41px;list-style-type: none !important; width:90px; border-radius:6px;font-size: 15px !important;}
#storeDiv #inside p{font-weight:550}

#vente div { margin:0 0 5px 0}
#achat #row-1 { grid-template-columns: 20px auto; align-items: center; text-align: center;}
#stock #row-1 { grid-template-columns: auto; align-items: center; text-align: center;}
.paperProd{
    margin: 5px 3px;
    width:170px;
    height:160px;
    background:white !important;
    border-radius:10px
}
.paperProd #row-1{
    grid-template-columns: auto auto; text-align:center; margin:0;
}
.paperProd #creator{display:grid;grid-template-columns: 86px auto; margin:3px 0;; padding:0 2px}
.paperProd #creator #a{justify-content:left;padding:0;text-align:center; overflow:hidden}
.paperProd #creator #b{text-align:right;justify-content:right; justify-items:flex-end;padding:0}
.paperProd #creator #a p,.paperProd #creator #b p{font-size:12px !important;font-weight:550 !important}

#catg .block-container{
  grid-gap: 3px;
}

#prdC, #mrqC {
  overflow: hidden ;
}

#sim-row{width:98%;display: grid;grid-template-columns: repeat(auto-fit, minmax(100px,1fr)) ;grid-gap: 10px;align-items: center;justify-content: center;}
#bdl-row,#bdl-check{width:98%;display: grid;grid-template-columns: 49% 49% ;grid-gap: 10px;align-items: center;justify-content: center;}
#bdl-ussd{width:98%;display: grid;grid-gap: 10px}
#sim-row1{width:98%;display: grid;grid-template-columns: 39% 59% ;grid-gap: 10px;align-items: center;justify-content: center;}
#bdl-note,#bdl-row-1{width:98%;}

.custom-suggestions-list {
  /* Ajoutez ici vos styles personnalisés pour la liste de suggestions */
  border: 1px solid #ccc;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
}
.bundles-det .jMeWFj{
  overflow-x: hidden !important;
}
.bdlSimContainer {
  display: grid;
  grid-template-rows: auto auto;
  overflow: hidden !important;
}

.simBdlContainer {
  display: grid;
  grid-template-rows: 35px auto auto;
  overflow: hidden !important;
}

.simBdlContainer .selectSim {
  height: 115px;
  align-items: center;
  display: grid;
}
.simBdlContainer .optionListContainer {
  z-index: 9000;
}

.simBdlContainer div:nth-child(2) {
  overflow: visible !important;
}

.simBdlContainer .react-swipeable-view-container div:first-child {
  overflow: visible !important;
}

#simBdl {
  min-height: 115px;
}
.simDetPaper {
  display: grid;
  margin: 10px 50px;
  padding: 5px 10px !important;
  background: var(--secondary-light) !important;
  border-radius: 10px;
}

.simDetPaper .row1 {

  grid-template-columns: repeat(5, 1fr) !important;
}

.simDetPaper .row2 {

  grid-template-columns: repeat(4, 1fr) !important;
}
.simDetPaper .row3 {

  grid-template-columns: repeat(2, 1fr) !important;
}

:is(#diagSim) .MuiDialog-paperScrollPaper {
  overflow: visible;
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
  #prod #row-1,#prod #row-2,#catg #row-1{ grid-column-gap: 3px;grid-row-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
  .prdPaper .grid-wrapper2{ grid-gap: 3px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
}
