#consom #row-1{display: grid;grid-template-columns: 13% 13% 12% 14% 12% 12% auto auto ;align-items: center ;}
#top #row-1{display: grid;grid-template-columns: 16% 16% 17% 19% 17%  auto ;align-items: center ;justify-content: center;}
#report-paper{width: 95%;background-color:#f6f6f6;margin-left: auto;margin-right: auto;margin-top: 15px;}
#report-paper .titleCharts{text-align: center !important;font-size: 16px ;font-weight: bold; margin:16px}
#report-paper #reports {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
#report-paper #reports1{justify-content: center;}

.custom-tooltip{width:280px;height: fit-content; background-color:white; text-align: center;margin-top:5px;justify-content: center;}
.custom-tooltip .label{ font-weight:bold;}
.custom-tooltip .intro{color:#343435;}
.custom-tooltip .intro1{color:#343435;}
#noconsom #row-1{
    display: grid;grid-template-columns: 20% 20% 160px auto;align-items: center ;
}
#noconsom {
    margin-bottom: 16px;}
#div-pies{display: grid; grid-template-columns: repeat(auto-fit, minmax(500px,1fr));justify-content: center;}
#div-tabs{display: grid; grid-template-columns: repeat(auto-fit, minmax(450px,1fr));justify-content: center;}

.legend{width:fit-content !important;height: 25px !important;padding: 3px;font-weight: 600;font-size: 13px ;
        margin-left: auto;margin-right: auto;margin-top: 3px;}

#consom #row-re,#sai #row-re{display: grid;grid-template-columns: 20% 20% 20% ;align-items: center ;justify-content: center;grid-gap: 5px; }

#sai #row-1{display:grid;grid-template-columns:20% 20% 22% 22% auto;}
#div-sai{display: grid;grid-template-columns: 71% 27%;grid-gap: 3px;}
@media only screen and (min-width: 150px) and (max-width: 1050px) {
    #consom #row-1,#sai #row-1{ grid-gap: 3px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;} 
}
@media only screen and (min-width: 150px) and (max-width: 650px) {
    #consom #row-re,#sai #row-re{display: grid;grid-template-columns: 50% 50% ;align-items: center ;justify-content: center;grid-gap: 5px;}
    #report-paper #reports{display: grid; grid-template-columns: repeat(auto-fit, minmax(360px,1fr));margin-left: 0px;margin-right: 0;}
    #div-pies{display: grid; grid-template-columns: repeat(auto-fit, minmax(400px,1fr));justify-content: center;}

}