html {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 400;
  font-style: normal;
}
.App {
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
}

