/* @import "theme.css";
@import "sprite.css";
@import "validator.css";
@import "ext/livedit.css"; */

/*
body{
   background-color: #FFF!important;
   font-family: "HelveticaW01-RoundedBd","Helvetica Neue","Helvetica","Arial","Microsoft YaHei New","Microsoft Yahei","SimSun","STXihei",sans-serif;
}*/
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap&text=0123456789");
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=regular');

body{
    background-color: #FFF!important;
    font-family: "Varela Round", sans-serif;
   }

div#global {
   width:100%;
   display:inline-block;
}
div#header,div#content,div#footer {
   /*max-width: 1400px;*/
   width: 100%;
   margin:auto;
}
div#header {
   height: 86px;
   top:0;
   background-image: url(../img/gplay.png);
}

div#menu {
   width: calc(100% - 280px);
   height: 30px;
   vertical-align: top;
   display: inline-block;
   font-weight: bold;
   padding: 5px 0 0 10px;
   white-space: nowrap;
}
div#menu ul {
   margin: 0;
   padding: 0;
   list-style: none;
   height: 31px;
}
div#menu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 31px;
   margin-right: .6em;
}
div#menu ul li a {
   text-decoration: none;
   color: var(--primary-main);
   font-size: .8em;
}
div#menu ul li a:hover,.link:hover {
   color:var(--primary-dark);
}

.selected::after {
   background-color: var(--primary-dark);
   content: '';
   position: absolute;
   z-index: -1;
   left: 50%;
   bottom: -8px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}
div#infos {
   display: inline-block;
   height: 35px;
   vertical-align: top;
   padding-top: 0px;
   text-align: right;
   width: 230px;
}

div#infos label.mtn {
	color: var(--primary-main);
}
div#infos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color: #626262;
	padding-right: 10px;
}
div#infos span#userName {
   font-size: .7em;
   font-weight: bold;
   color: #108000;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
}

div#infos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
}
div#infos img#icoUser,div#minfos img#icoUser {
   width: 20px;
   height: 20px;
   position: relative;
   top: 3px;
}
div#infos form#f,div#minfos form#f {
   display: inline-block;
   margin-bottom: 7px;
}
div#infos select,div#minfos select {
   border: solid 1px var(--primary-main);
   font-size: .6em;
   width: 80px;
   vertical-align: super;
}
div#sMenu {
   width: 100% ;
   height: 32px;
   background-color: var(--primary-dark);
   display: inline-block;
   font-weight: bold;
   vertical-align: top;
   padding-left: 20px;
}
div#sMenu ul {
   margin: 0;
   list-style: none;
   height: 32px;
}
div#sMenu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 32px;
   margin-right: .7em;
   color: white;
   padding: 3px 5px 9px 5px;
   box-sizing: content-box !important;
}
.link2 {
   position: relative;
   list-style-type: none;
   overflow: hidden;
   color: white;
   height:50px;
   padding: 10px 20px;
   box-sizing: content-box !important;
}
div#sMenu ul li a,.link2 {
   text-decoration: none;
   color: inherit;
   font-size: .9em;
}
div#sMenu ul li:hover,.link2:hover {
   color:#9C9C9C!important;
}
.selected2::after {
   background-color: var(--yellow-dark);
   content: '';
   position: absolute;
   z-index: 1;
   left: 50%;
   bottom: 4px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}

.mselected2{
   color:#9C9C9C!important;
}

div#contentTitle {
   width: 100% ;
   height: 18px;
   border-bottom: solid 1px;
   padding-right: 10px;
   font-size: .9em;
   font-weight: bold;
   background-color: var(--yellow-dark);
   color: var(--primary-dark);
   text-align: right;
   z-index: 1;
   position: relative;
}
.content {
   /*border-top: 0;
   border-bottom: 0;*/
   overflow: auto;
   display: block;
   height: calc(100% - 105px);
  /* margin-top: 0px;
   padding-left:0px;*/
   padding:10px 0;
   width: 100% ;
   top:86px;
   white-space: pre-line;
   position: fixed;
   max-width: 2000px;
   margin: 0 auto !important;  
}
div#main {
   width: calc(100% - 160px);
   height:calc(100% - 5px);
   display:inline-block;
   vertical-align:top;
}
div#marge {
   width:150px;
   height:calc(100% - 5px);
   /* background-color:rgba(128; 128; 128; 0.11); */
   display:inline-block;
}
div#footer {
   border: solid 1px;
   height: 18px;
   background: var(--primary-dark);
   bottom:0;
   position: fixed;
   color: white;
   display: flex;
}
/* div#footer #logoDA{
   background: url(../img/logo_digitarts_boutiqa.png) no-repeat;
   width:100px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 100px;
}
div#footer #logoLicence{
   background: url(../img/logo_iraha_white.png) no-repeat;
   width:70px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 70px;
} */
div#footer span#copyright, div#footer span#lastMAJ, div#footer span#powered {
   font-size: .8em;
   font-weight: bold;
}
div#footer span#copyright{
   position: absolute;
   bottom: 0;
   left: 0;
   margin-left: 5px;
}
div#footer span#copyright1{
   width: 100%;
   height: 100%;
   position: absolute;
   bottom: 0;
   text-align: center;
   font-size: 11px;
}
div#footer span#lastMAJ{
   position: absolute;
   bottom: 0;
   right: 0;
   margin-right: 10px;
}
div#footer span#powered{
   position: absolute;
   bottom: 0;
   left: calc(50% - 140px);
}

/* mobile*/
.link {
   text-decoration: none;
   color: var(--primary-main);
   font-size: 1rem;
}

div#minfos {
   display: inline-block;
   height: fit-content !important;
   width: fit-content !important;
   min-width: 75px;
   vertical-align: top;
   padding-top: 0px;
   text-align: right;
   align-self:right;
   flex-grow: 1;
   color:white;
}
div#minfos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color:white;
	padding-right: 10px;
}

div#minfos span#userName {
   font-size: .7em;
   font-weight: bold;
   color:white;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
}

div#minfos span#userName a {
   color:white;
   font-weight: normal; 
}
div#minfos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
   color:white;
}

.link2 {
   position: relative;
   list-style-type: none;
   overflow: hidden;
   height:50px;
   padding: 10px 20px;
   box-sizing: content-box !important;
}
.mselected2{
   color:#9C9C9C!important;
}

.mobileBigTitle{
   font-size: 1rem;
 }
.mobileSmallTitle{
   font-size: 0.9rem;
 }

 .content2{
   display:grid;
   overflow: auto;
   height: calc(100% - 105px);
  /* margin-top: 0px;
   padding-left:0px;*/
   padding:10px 0;
   width: 100% ;
   top:86px;
   white-space: pre-line;
   position: fixed;
   max-width: 2000px;
   margin: 0 auto !important;  
}

@media only screen and (min-width: 150px) and (max-width: 599px) {
   .content {
      top:57px;
      bottom:17px;
      height: calc(100% - 75px);
   }
}