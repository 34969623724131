#icons #row-1 {  grid-template-columns:  20px 20px; grid-gap: 5px;}
#ticket{border:1px solid black;margin:10px;padding:5px; min-width: 480px;border-radius:10px;text-align:center}
#ticket #titre{color: gray;}
#ticket #ticketHead{text-align:center;height:fit-content ;}
#ticket #divinfo{text-align:center}
#ticket #divinfo #row1{display:grid;grid-template-columns:30% 30% 39%;padding:20px}
#ticket #row2{display:grid;grid-template-columns:70% 30%;padding:20px}
#ticket #prod{display:grid;grid-template-columns:65% 35%;padding:20px;text-align:center}
#ticket #ticketHead #logo_ticket{  height:50px; margin:0; padding: 0; }

#ticket #row-1{grid-template-columns: auto auto auto; font-size: 14px;}
#ticket #row-1 p:first-child{text-align: start;}
#ticket #row-1 p:last-child{text-align: end;}
#ticket #row-2 p:first-child{text-align: start;}
#ticket #row-2 p:last-child{text-align: end;}
#ticket #row-2{grid-template-columns: auto auto;; font-size: 14px;}
#ticket #row-3{grid-template-columns: auto auto;justify-items: end;}
#ticket #row-4{justify-items: end;}

.dash{
    border: 0 none;
    border-top: 2px dashed #322f32;
    background: none;
    height:0;
    min-width: 480px;
  } 
#bon{grid-template-columns:270px 200px; grid-gap: 14px;display: grid;margin-top: 15px;}
.bon-ach{width:96%;height: auto;margin-left: 2%;margin-top:1%;}
.bon-ach .bon-head{width:96%;height: auto; display: flex;margin-top: 15px; padding: 5px;margin-left: 2%;margin-bottom: 2%;}
.bon-ach #infoG{width:45%;height: auto;}
.bon-ach #infoD{width:45%;height: auto;text-align: end;}
.bon-ach #logo{height:70px;}
.bon-ach #infos{margin-bottom: 5px;}
.bon-ach #mtn{font-size: medium;margin-top: 20px;text-align: end;margin-right:14px;}
.bon-ach #solde{width:100% ;text-align: end; }
.bon-ach #solde #a{margin:20px 2%;padding: 0 5px; display: block;width:200px ; float: right;}
.bon-ach #solde #b{margin:20px 2%;padding: 0 5px; display: block;width:200px ; float: left;}
.bon-ach #solde #a #mtn{ border: 1px solid black; margin:0; padding: 3px; background: var(--secondary-main);text-align:right !important;}
.bon-ach #solde #b #mtn{ border: 1px solid black; margin:0; padding: 3px; background: var(--secondary-main);text-align:left !important;}
.bon-ach #solde #mtnM{ border: 1px solid black;border-top: none;border-bottom: none; margin:0;padding: 5px;background: var(--secondary-main);text-align:right !important;}
.bon-ach #total-sold{ width: 96% !important; border: 1px solid black;}
 #tr{ width: 100%;}
#bonBon{
    grid-template-columns: auto 200px 250px;
}
.boncat{width:96%;height: auto;margin-left: 2%;margin-top:2%;margin-bottom: 2%;}
.boncat .bon-head{width:96%;height: auto; display: flex;margin-top: 15px; padding: 5px;margin-left: 2%;margin-bottom: 2%;}
.boncat #infoG{width:85%;height: auto;}
.boncat #logo{height:70px;}
.boncat #infos{margin-bottom: 5px;}

.boncat{width:96%;height: auto;margin-left: 2%;margin-top:2%;margin-bottom: 2%;}
.boncat .bon-head{width:96%;height: auto; display: flex;margin-top: 15px; padding: 5px;margin-left: 2%;margin-bottom: 2%;}
.boncat #infoG{width:85%;height: auto;}
.boncat #log{width:15%;height: auto;}
.boncat #infos{margin-bottom: 5px;}

.nodata{width:100%;height:100%;text-align: center !important; justify-content: center !important; align-items: center!important;}
#cont{width:fit-content; height: fit-content;margin: auto;}
.message { text-align: center; font-size: 30px; font-family: 'Signika', sans-serif; padding-top: 20px; padding-bottom: 20px;}
.nodata .notFound{width:250px;height:250px; display: inline;}
.nodata .notAllowed{width:250px;height:250px; display: inline;}
.nodata .message{color:gray; padding:0; font-size: 26px; font-family: 'Signika', sans-serif;}