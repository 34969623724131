#userForm{
    display: grid;
    grid-gap: 10px;
  }
.userRoleontainer {
  display: grid;
  grid-template-rows: auto auto;
  overflow: hidden !important;
}
  #rolC{
    overflow: hidden;
  }
  :is(#diagRol,#diagUser) .MuiDialog-paperScrollPaper{
    overflow: visible;
  }